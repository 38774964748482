<template>
  <div class="liquidity-remove-wrapper">
    <div class="header">
      <div class="title">{{$t('liquidity.amount')}}</div>
      <div class="setting">
        <el-popover placement="bottom-end"
                    width="250"
                    v-model="setvisible">
          <span>{{$t('swap.slippage')}}</span>
          <el-radio-group v-model="slippage">
            <el-radio :label="5">0.5%</el-radio>
            <el-radio :label="10">1.0%</el-radio>
            <el-radio :label="20">2.0%</el-radio>
          </el-radio-group>
          <i class="el-icon-setting"
             slot="reference"></i>
        </el-popover>
      </div>
    </div>
    <div class="body">
      <div class="val">
        <span>{{value}} %</span>
      </div>
      <div class="block">
        <el-slider v-model="value"
                   @change="sliderChange()"
                   :show-tooltip="false"></el-slider>
      </div>
      <div class="btn-wrapper">
        <div :class="btnVal == 1? 'active btn':'btn'"
             @click="setVal(25)">25%</div>
        <div :class="btnVal == 2? 'active btn':'btn'"
             @click="setVal(50)">50%</div>
        <div :class="btnVal == 3? 'active btn':'btn'"
             @click="setVal(75)">75%</div>
        <div :class="btnVal == 4? 'active btn':'btn'"
             @click="setVal(100)">MAX</div>
      </div>
    </div>
    <div class="button">
      <div v-if="buttonStatus == 0"
           class="liquidity disabled"
           :disabled="disabled">{{$t('liquidity.connect')}}{{buttonStatus}}</div>
      <div v-if="buttonStatus == 1"
           class="liquidity"
           @click="removeLiquidity()">{{$t('liquidity.title2')}}</div>
      <div v-if="buttonStatus == 2"
           class="liquidity disabled"
           :disabled="disabled">{{$t('liquidity.enter')}}</div>
    </div>
    <div class="receive">
      <div class="title">{{$t('liquidity.get')}}</div>
      <div class="item">
        <span>{{showAmount(token1Amount) > 0? showAmount(token1Amount).toFixed(15) : '-'}}</span>
        <span>SUSD</span>
      </div>
      <div class="item">
        <span>{{showAmount(token2Amount) > 0? showAmount(token2Amount).toFixed(6) : '-'}}</span>
        <span>USDC</span>
      </div>
      <div class="item">
        <span>{{showAmount(token3Amount) > 0? showAmount(token3Amount).toFixed(6) : '-'}}</span>
        <span>USDT</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getContractState, getBalanceOfLp, getLpTotalSupply, removeLiquidity, getState } from '../../contract/contracts'
export default {
  name: "liquidityIndex",
  props: {
    msg: String,
  },
  computed: {
    buttonStatus: function () {
      let res
      if (!this.address) {
        res = 0
      } else {
        if (this.showAmount(this.token1Amount + this.token2Amount + this.token3Amount) > 0) {
          res = 1
        } else {
          res = 2
        }
      }
      return res
    }
  },
  watch: {
    address: function () {
      console.log(this.address)
    },
    selfLp: async function () {
      this.getLpTotalSupply()
      let res = await this.getContractState()
      this.liquidity = getState(res.balances)
      console.log(this.liquidity)
      this.token1Amount = this.liquidity['SUSD']
      this.token2Amount = this.liquidity['USDC']
      this.token3Amount = this.liquidity['USDT']
    },
  },
  data () {
    return {
      slippage: 5,
      setvisible: false,
      liquidity: {},
      disabled: true,
      address: sessionStorage.getItem("alley_address"),
      btnVal: 0,
      value: 0,
      selfLp: 0,
      totalLp: 0,
      token1Amount: 0,
      token2Amount: 0,
      token3Amount: 0,
    }
  },
  created () {
    this.connect()
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.getBalanceOfLp()
    },
    showAmount (val) {
      return ((val * this.value) / 100) * (1000 - Number(this.slippage)) / 1000
    },
    connect () {
      let btn = document.getElementById('connectwallet')
      if (btn) {
        btn.click()
      }
    },
    sliderChange () {
      this.btnVal = 0
    },
    setVal (val) {
      this.value = val
      switch (val) {
        case 25:
          this.btnVal = 1
          break;
        case 50:
          this.btnVal = 2
          break;
        case 75:
          this.btnVal = 3
          break;
        case 100:
          this.btnVal = 4
          break;
        default:
          this.btnVal = 0
          break;
      }
    },
    async getBalanceOfLp () {
      try {
        let res = await getBalanceOfLp()
        this.selfLp = res / 1000000000000000000
        console.log(res)
        console.log(this.selfLp)
      } catch (e) {

      }
    },
    async getLpTotalSupply () {
      try {
        let res = await getLpTotalSupply()
        this.totalLp = res / 1000000000000000000
        console.log(res)
        console.log(this.totalLp)
      } catch (e) {

      }
    },
    async getContractState () {
      try {
        let res = getContractState()
        return res
      } catch (e) {

      }
    },
    async removeLiquidity () {
      try {
        let lp = (this.selfLp * this.value) / 100
        let susd = this.showAmount(this.token1Amount).toFixed(15)
        let usdt = this.showAmount(this.token3Amount).toFixed(6)
        let usdc = this.showAmount(this.token2Amount).toFixed(6)
        let res = await removeLiquidity(lp, susd, usdt, usdc)
        console.log(res)
        this.value = 0
        this.init()
      } catch (e) {
        console.log(e)
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.el-dropdown-menu {
  .el-dropdown-menu__item {
    display: flex;
    align-items: center;
    img {
      height: 28px;
      margin-right: 5px;
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.el-popover {
  width: 200px;
  height: 100px;
  border-radius: 10px;
  .el-radio-group {
    margin-top: 10px;
    display: flex;
    .el-radio {
      margin-right: 20px;
    }
  }
}
.el-slider__bar {
  background-color: rgb(255, 0, 122);
}
.el-slider__button {
  border-color: rgb(255, 0, 122);
}
</style>
<style scoped lang="less">
.liquidity-remove-wrapper {
  width: 400px;
  height: 700px;
  position: absolute;
  top: 0px;
  padding-top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px,
    rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.01) 0px 24px 32px;
  border-radius: 30px;
  .header {
    padding: 12px 30px 0px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 20px;
    }
    .setting {
      i {
        font-size: 25px;
      }
    }
  }
  .body {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 20px;
    .block {
      width: 80%;
    }
    .btn-wrapper {
      width: 80%;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      .btn {
        cursor: pointer;
        width: 50px;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
        background-color: rgba(0, 0, 0, 0.2);
        color: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
      }
      .active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .val {
      span {
        font-size: 50px;
        line-height: 60px;
      }
    }
  }
  .button {
    padding: 12px 20px;
    .liquidity {
      cursor: pointer;
      padding: 18px;
      width: 100%;
      font-weight: 500;
      text-align: center;
      border-radius: 20px;
      outline: none;
      border: 1px solid transparent;
      text-decoration: none;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      z-index: 1;
      background-color: rgb(255, 0, 122);
      color: white;
    }
    .disabled {
      background-color: rgb(237, 238, 242);
      color: rgb(136, 141, 155);
      cursor: pointer;
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
      opacity: 1;
    }
  }
  .receive {
    padding: 12px 20px;
    display: flex;
    flex-flow: column;
    align-items: baseline;
    .title {
      font-size: 22px;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.6);
    }
    .item {
      padding: 10px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 18px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>
