<template>
  <div class="liquidity-content">
    <el-tabs v-model="activeName"
             type="card">
      <el-tab-pane :label="$t('liquidity.title1')"
                   name="add">{{$t('liquidity.title1')}}</el-tab-pane>
      <el-tab-pane :label="$t('liquidity.title2')"
                   name="remove">{{$t('liquidity.title2')}}</el-tab-pane>
    </el-tabs>
    <add-div v-if="activeName == 'add'"></add-div>
    <remove-div v-if="activeName == 'remove'"></remove-div>
  </div>
</template>

<script>
import addDiv from "./add"
import removeDiv from "./remove"
export default {
  name: "liquidityIndex",
  components: {
    addDiv,
    removeDiv
  },
  props: {
    msg: String,
  },
  computed: {
  },
  data () {
    return {
      activeName: 'add'
    }
  },
  mounted () {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.el-tabs__content{
  display: none;
}
.el-tabs__nav{
  border: none !important;
}
.el-tabs__item{
  border: none !important;
}
.el-tabs__item:hover{
  color: #ff007a;
}
.el-tabs__item.is-active{
  color: #ff007a;
}
</style>
<style scoped lang="less">
.liquidity-content {
  width: 400px;
  position: absolute;
  top: 30vh;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(255, 255, 255);
    box-shadow: rgba(0,0,0,0.01) 0px 0px 1px, rgba(0,0,0,0.04) 0px 4px 8px, rgba(0,0,0,0.04) 0px 16px 24px, rgba(0,0,0,0.01) 0px 24px 32px;
    border-radius: 30px;
}
</style>
