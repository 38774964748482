<template>
  <div class="liquidity">
    <index-div></index-div>
  </div>
</template>

<script>
// @ is an alias to /src
import indexDiv from "@/components/liquidity/index.vue";

export default {
  name: "Home",
  components: {
    indexDiv
  },
};
</script>
<style lang="less" scoped>
.liquidity{
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  background-image: radial-gradient(50% 50% at 50% 50%, rgba(255, 0, 122, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
}
</style>
