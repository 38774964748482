<template>
  <div class="liquidity-wrapper">
    <div class="header">
      <div class="title"></div>
      <div class="fixed">
        <el-checkbox v-model="fixed">{{$t('liquidity.fixed')}}</el-checkbox>
      </div>
    </div>
    <div class="body">
      <div class="from input-wrapper">
        <div class="top">
          <div class="title">{{$t('liquidity.amount')}}</div>
          <div class="balance">{{$t('liquidity.balance')}}:{{tokenBalance.SUSD}}</div>
        </div>
        <div class="down">
          <input v-model="token1Amount" />
          <span class="token-name">
            <img :src="tokenList[0].url" /><span>{{tokenList[0].name}}</span>
          </span>
        </div>
      </div>
      <i class="el-icon-plus sub"></i>
      <div class="to input-wrapper">
        <div class="top">
          <div class="title">{{$t('liquidity.amount')}}</div>
          <div class="balance">{{$t('liquidity.balance')}}:{{tokenBalance.USDT}}</div>
        </div>
        <div class="down">
          <input :disabled="fixed"
                 v-model="token2Amount" />
          <span class="token-name">
            <img :src="tokenList[1].url" /><span>{{tokenList[1].name}}</span>
          </span>
        </div>
      </div>
      <i class="el-icon-plus sub"></i>
      <div class="to input-wrapper">
        <div class="top">
          <div class="title">{{$t('liquidity.amount')}}</div>
          <div class="balance">{{$t('liquidity.balance')}}:{{tokenBalance.USDC}}</div>
        </div>
        <div class="down">
          <input :disabled="fixed"
                 v-model="token3Amount" />
          <span class="token-name">
            <img :src="tokenList[2].url" /><span>{{tokenList[2].name}}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="button">
      <div v-if="buttonStatus == 0"
           class="liquidity disabled"
           :disabled="disabled"
           @click="connect()">{{$t('liquidity.connect')}}</div>
      <div v-if="buttonStatus == 1"
           @click="approveAddLiquidity()"
           class="liquidity">{{$t('liquidity.title1')}}</div>
      <div v-if="buttonStatus == 2"
           class="liquidity disabled"
           :disabled="disabled">{{$t('liquidity.insufficient')}}</div>
    </div>
  </div>
</template>

<script>
import { fetchBalances, getContractState, approveAddLiquidity, getState } from '../../contract/contracts'
export default {
  name: "liquidityIndex",
  props: {
    msg: String,
  },
  computed: {
    buttonStatus: function () {
      let res
      if (this.address) {
        res = 1
      } else {
        res = 0
      }
      if (this.token1Amount > this.tokenBalance.SUSD  || this.token2Amount > this.tokenBalance.USDT || this.token3Amount > this.tokenBalance.USDC) {
        res = 2
      }
      return res
    }
  },
  watch: {
    address: function () {
      console.log(this.address)
    },
    token1Amount: async function () {
      if (this.fixed) {
        if (this.token1Amount !== 0) {
          this.token2Amount = ((this.token1Amount * this.liquidity['USDT']) / this.liquidity['SUSD']).toFixed(6)
          this.token3Amount = ((this.token1Amount * this.liquidity['USDC']) / this.liquidity['SUSD']).toFixed(6)
        }
      }
    },
    fixed: async function () {
      let res = await this.getContractState()
      this.liquidity = getState(res.balances)
      console.log(this.liquidity)
      if (this.fixed) {
        this.token1Amount = 0
      } else {
        this.token1Amount = 0
        this.token2Amount = 0
        this.token3Amount = 0
      }
    }
  },
  data () {
    return {
      slippage: 5,
      setvisible: false,
      disabled: true,
      fixed: false,
      address: sessionStorage.getItem("alley_address"),
      token1Amount: 0,
      token2Amount: 0,
      token3Amount: 0,
      liquidity: {},
      tokenList: [
        {
          name: "SUSD",
          url: require("../../assets/token/SUSD.svg"),
          val: 0
        },
        {
          name: "USDT",
          url: require("../../assets/token/USDT.svg"),
          val: 1
        },
        {
          name: "USDC",
          url: require("../../assets/token/USDC.svg"),
          val: 2
        },
      ],
      tokenBalance: {}
    }
  },
  created () {
    this.connect()
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.token1Amount = 0
      this.token2Amount = 0
      this.token3Amount = 0
      this.fetchBalances()
    },
    connect () {
      let btn = document.getElementById('connectwallet')
      if (btn) {
        btn.click()
      }
    },
    async fetchBalances () {
      this.tokenBalance = await fetchBalances(this.address)
      console.log(this.tokenBalance)
      console.log(this.tokenBalance['USDC'])
    },
    async approveAddLiquidity () {
      try {
        const a = Number(this.token1Amount)
        const b = Number(this.token2Amount)
        const c = Number(this.token3Amount)
        const minMinted = (a + b + c) * 100 / 110
        let res = await approveAddLiquidity(a, b, c, minMinted)
        this.init()
      } catch (e) {
        console.log(e)
      }
    },
    async getContractState () {
      try {
        let res = getContractState()
        return res
      } catch (e) {

      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.el-dropdown-menu {
  .el-dropdown-menu__item {
    display: flex;
    align-items: center;
    img {
      height: 28px;
      margin-right: 5px;
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.el-popover {
  width: 200px;
  height: 100px;
  border-radius: 10px;
  .el-radio-group {
    margin-top: 10px;
    display: flex;
    .el-radio {
      margin-right: 20px;
    }
  }
}
</style>
<style scoped lang="less">
.liquidity-wrapper {
  width: 400px;
  height: 700px;
  position: absolute;
  top: 0px;
  padding-top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 1px, rgba(0, 0, 0, 0.04) 0px 4px 8px,
    rgba(0, 0, 0, 0.04) 0px 16px 24px, rgba(0, 0, 0, 0.01) 0px 24px 32px;
  border-radius: 30px;
  .header {
    padding: 12px 30px 0px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 20px;
    }
    .setting {
      i {
        font-size: 25px;
      }
    }
  }
  .body {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 20px;
    .input-wrapper {
      width: 100%;
      border-radius: 20px;
      border: 1px solid rgb(247, 248, 250);
      background-color: rgb(255, 255, 255);
      display: flex;
      flex-flow: column;
      padding: 12px 20px;
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-weight: 600;
          font-size: 14px;
          color: rgb(86, 90, 105);
        }
        .balance {
          font-weight: 600;
          font-size: 14px;
          color: rgb(86, 90, 105);
        }
      }
      .down {
        padding-top: 20px;
        display: flex;
        align-items: center;
        .token-name {
          display: flex;
          align-items: center;
          img {
            height: 28px;
            margin-right: 5px;
          }
          span {
            font-size: 18px;
          }
        }
        input {
          color: rgb(0, 0, 0);
          position: relative;
          font-weight: 500;
          outline: none;
          border: none;
          flex: 1 1 auto;
          background-color: rgb(255, 255, 255);
          font-size: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0px;
          appearance: textfield;
        }
        .max {
          padding: 0 10px;
          width: 60px;
          height: 28px;
          background-color: rgb(253, 234, 241);
          border: 1px solid rgb(253, 234, 241);
          border-radius: 7rem;
          font-size: 14px;
          line-height: 28px;
          font-weight: 500;
          cursor: pointer;
          margin-right: 7px;
          color: rgb(255, 0, 122);
        }
        .el-dropdown {
          cursor: pointer;
          .el-dropdown-link {
            display: flex;
            align-items: center;
            img {
              height: 28px;
              margin-right: 5px;
            }
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
    .sub {
      color: rgb(255, 0, 122);
      padding: 20px 0 20px;
    }
  }
  .button {
    padding: 12px 20px;
    .liquidity {
      cursor: pointer;
      padding: 18px;
      width: 100%;
      font-weight: 500;
      text-align: center;
      border-radius: 20px;
      outline: none;
      border: 1px solid transparent;
      text-decoration: none;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      z-index: 1;
      background-color: rgb(255, 0, 122);
      color: white;
    }
    .disabled {
      background-color: rgb(237, 238, 242);
      color: rgb(136, 141, 155);
      cursor: pointer;
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
      opacity: 1;
    }
  }
}
</style>
