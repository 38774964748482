<template>
  <div class="swap-wrapper">
    <div class="header">
      <div class="title">{{$t('swap.title')}}</div>
      <div class="setting">
        <el-popover placement="bottom-end"
                    width="250"
                    v-model="setvisible">
          <span>{{$t('swap.slippage')}}</span>
          <el-radio-group v-model="slippage">
            <el-radio :label="5">0.5%</el-radio>
            <el-radio :label="10">1.0%</el-radio>
            <el-radio :label="20">2.0%</el-radio>
          </el-radio-group>
          <i class="el-icon-setting"
             slot="reference"></i>
        </el-popover>
      </div>
    </div>
    <div class="body">
      <div class="from input-wrapper">
        <div class="top">
          <div class="title">{{$t('swap.from')}}</div>
          <div class="balance">{{$t('swap.balance')}}:{{tokenBalance[fromTokenName]}}</div>
        </div>
        <div class="down">
          <input v-model="fromAmount" />
          <div class="max" @click="max()">{{$t('swap.max')}}</div>
          <el-dropdown @command="select1">
            <span class="el-dropdown-link">
              <img :src="fromTokenUrl" /><span>{{fromTokenName}}</span><i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in tokenList"
                                :command="item.val"
                                :key="index"><img :src="item.url" /> <span>{{item.name}}</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <i class="el-icon-bottom sub"
         @click="change()"></i>
      <div class="to input-wrapper">
        <div class="top">
          <div class="title">{{$t('swap.to')}}</div>
        </div>
        <div class="down">
          <input disabled v-model="toAmount" />
          <el-dropdown @command="select2">
            <span class="el-dropdown-link">
              <img :src="toTokenUrl" /><span>{{toTokenName}}</span><i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in tokenList"
                                :command="item.val"
                                :key="index"><img :src="item.url" /> <span>{{item.name}}</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="price" v-if="price > 0">
      <span>{{$t('swap.price')}}:</span><span>{{price}} {{fromTokenName}} per 1 {{toTokenName}}</span>
    </div>
    <div class="button">
      <div v-if="buttonStatus == 0"
           class="swap disabled"
           :disabled="disabled"
           @click="connect()">{{$t('swap.connect')}}</div>
      <div v-if="buttonStatus == 1"
           @click="exchange()"
           class="swap">{{$t('swap.title')}}</div>
      <div v-if="buttonStatus == 2"
           class="swap disabled"
           :disabled="disabled">{{$t('swap.insufficient')}}</div>
    </div>
    <div class="minGet" v-if="minGet > 0">
      <span>{{$t('swap.min')}}:</span><span>{{minGet}} {{toTokenName}}</span>
    </div>
  </div>
</template>

<script>
import { fetchBalances, exchange, getSwappedTokenAmount } from '../../contract/contracts'
export default {
  name: "swapIndex",
  props: {
    msg: String,
  },
  computed: {
    buttonStatus: function () {
      let res
      if (this.address) {
        res = 1
      } else {
        res = 0
      }
      if (this.fromAmount > this.tokenBalance[this.fromTokenName]) {
        res = 2
      }
      return res
    },
    minGet:function (){
      let res = 0
      if(this.fromAmount == 0){
        res = 0
      }else{
        res = (Number(this.toAmount) * (1000 - Number(this.slippage)) / 1000).toFixed(6)
      }
      return res
    }
  },
  watch: {
    address: function () {
      console.log(this.address)
    },
    fromAmount: async function () {
      if(this.fromAmount == 0){
        this.toAmount = 0
        this.price = 0
      }else{
        this.toAmount = await this.getSwappedTokenAmount(this.fromAmount)
        this.price = (this.fromAmount/this.toAmount).toFixed(6)
      }
    }
  },
  data () {
    return {
      slippage: 5,
      setvisible: false,
      disabled: true,
      address: sessionStorage.getItem("alley_address"),
      fromTokenName: 'USDT',
      fromCommand: 0,
      fromAmount: 0,
      fromBalance: 0,
      fromTokenUrl: require("../../assets/token/USDT.svg"),
      toTokenName: 'USDC',
      toCommand: 1,
      toAmount: 0,
      toBalance: 0,
      toTokenUrl: require("../../assets/token/USDC.svg"),
      price: 0,
      backAmount: 0,
      tokenList: [
        {
          name: "USDT",
          url: require("../../assets/token/USDT.svg"),
          val: 0
        },
        {
          name: "USDC",
          url: require("../../assets/token/USDC.svg"),
          val: 1
        },
        {
          name: "SUSD",
          url: require("../../assets/token/SUSD.svg"),
          val: 2
        },
      ],
      tokenBalance: {}
    }
  },
  created () {
    this.connect()
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.fromAmount = 0
      this.toAmount = 0
      this.fetchBalances()
    },
    connect () {
      let btn = document.getElementById('connectwallet')
      if (btn) {
        btn.click()
      }
    },
    select1 (command) {
      let originName = this.fromTokenName
      let originUrl = this.fromTokenUrl
      let originCommand = this.fromCommand
      this.fromTokenName = this.tokenList[command].name
      this.fromTokenUrl = this.tokenList[command].url
      this.fromCommand = this.tokenList[command].val
      if (this.fromTokenName == this.toTokenName) {
        this.select2(originCommand)
      }
      this.fromAmount = 0
      this.toAmount = 0
    },
    select2 (command) {
      let originName = this.toTokenName
      let originUrl = this.toTokenUrl
      let originCommand = this.toTokenCommand
      this.toTokenName = this.tokenList[command].name
      this.toTokenUrl = this.tokenList[command].url
      this.toCommand = this.tokenList[command].val
      if (this.fromTokenName == this.toTokenName) {
        this.select1(originCommand)
      }
      this.fromAmount = 0
      this.toAmount = 0
    },
    change () {
      this.select1(this.toCommand)
      this.fromAmount = 0
      this.toAmount = 0
    },
    max () {
      this.fromAmount = this.tokenBalance[this.fromTokenName]
    },
    async fetchBalances () {
      this.tokenBalance = await fetchBalances(this.address)
      console.log(this.tokenBalance)
      console.log(this.tokenBalance['USDC'])
    },
    async getSwappedTokenAmount (amount) {
      try {
        let res = await getSwappedTokenAmount(this.fromTokenName, amount, this.toTokenName)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async exchange () {
      try {
        const minOut = Number(this.toAmount) * (1000 - Number(this.slippage)) / 1000
        let res = await exchange(this.fromTokenName, this.toTokenName, this.fromAmount, minOut)
        console.log("success")
        this.init()
      } catch (e) {
        console.log(e)
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.el-dropdown-menu {
  .el-dropdown-menu__item {
    display: flex;
    align-items: center;
    img {
      height: 28px;
      margin-right: 5px;
    }
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.el-popover {
  width: 200px;
  height: 100px;
  border-radius: 10px;
  .el-radio-group {
    margin-top: 10px;
    display: flex;
    .el-radio {
      margin-right: 20px;
    }
  }
}
</style>
<style scoped lang="less">
.swap-wrapper {
  width: 400px;
  height: 500px;
  position: absolute;
  top: 30vh;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(255, 255, 255);
  box-shadow: 1px 1px 10px #b7aeb7;
  border-radius: 30px;
  .header {
    padding: 12px 30px 0px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 20px;
    }
    .setting {
      i {
        font-size: 25px;
      }
    }
  }
  .price{
    display: flex;
    justify-content: space-between;
    padding:0 20px;
  }
  .body {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 20px;
    .input-wrapper {
      width: 100%;
      border-radius: 20px;
      border: 1px solid rgb(247, 248, 250);
      background-color: rgb(255, 255, 255);
      display: flex;
      flex-flow: column;
      padding: 12px 20px;
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-weight: 600;
          font-size: 14px;
          color: rgb(86, 90, 105);
        }
        .balance {
          font-weight: 600;
          font-size: 14px;
          color: rgb(86, 90, 105);
        }
      }
      .down {
        padding-top: 20px;
        display: flex;
        align-items: center;
        input {
          color: rgb(0, 0, 0);
          position: relative;
          font-weight: 500;
          outline: none;
          border: none;
          flex: 1 1 auto;
          background-color: rgb(255, 255, 255);
          font-size: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0px;
          appearance: textfield;
        }
        .max {
          padding: 0 10px;
          width: 60px;
          height: 28px;
          background-color: rgb(253, 234, 241);
          border: 1px solid rgb(253, 234, 241);
          border-radius: 7rem;
          font-size: 14px;
          line-height: 28px;
          font-weight: 500;
          cursor: pointer;
          margin-right: 7px;
          color: rgb(255, 0, 122);
        }
        .el-dropdown {
          cursor: pointer;
          .el-dropdown-link {
            display: flex;
            align-items: center;
            img {
              height: 28px;
              margin-right: 5px;
            }
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
    .sub {
      cursor: pointer;
      color: rgb(255, 0, 122);
      padding: 40px 0 40px;
    }
  }
  .button {
    padding: 12px 20px;
    .swap {
      cursor: pointer;
      padding: 18px;
      width: 100%;
      font-weight: 500;
      text-align: center;
      border-radius: 20px;
      outline: none;
      border: 1px solid transparent;
      text-decoration: none;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      position: relative;
      z-index: 1;
      background-color: rgb(255, 0, 122);
      color: white;
    }
    .disabled {
      background-color: rgb(237, 238, 242);
      color: rgb(136, 141, 155);
      cursor: pointer;
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
      opacity: 1;
    }
  }
}
</style>
